@font-face {
    font-family: 'Frozito';
    src: url('../font/Frozito.woff2') format('woff2'),
        url('../font/Frozito.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

body {
    background: #130918;
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none !important;
}

.btn {
    padding: 14px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px !important;
    line-height: 26px;
    outline: none !important;
    box-shadow: none !important;
}

.border-btn {
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 13px;
    -webkit-border-radius: 13px;
    -moz-border-radius: 13px;
    -ms-border-radius: 13px;
    -o-border-radius: 13px;
}

.border-btn-dark {
    background: #4f618b;
    border: 2px solid #130918;
    color:#ffffff;
    -webkit-border-radius: 13px;
}

.yellow-bg-btn {
    background: #F1AA1C;
    border: 2px solid #F1AA1C;
    color: #fff;
    border-radius: 13px;
    -webkit-border-radius: 13px;
    -moz-border-radius: 13px;
    -ms-border-radius: 13px;
    -o-border-radius: 13px;
}

.header-main-section {
    position: absolute;
    z-index: 9999;
    width: 100%;
}

.header-main-section .header-main-sec {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-main-section .header-main-sec .navbar-brand img{
    width: 145px;
}
.header-main-section .header-main-sec .navbar-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header-main-section .header-main-sec .navbar-menu li {
    margin: 0 5px;
}

.btn.border-btn:hover {
    background-color: transparent;
    color: yellow; /* Optional: change the text color on hover if needed */
  }

.hover-svg-menu-medium {
    width: 43px;
    height: 43px;
    object-fit: contain;
  }

.hover-svg-menu {
    width: 40px;
    height: 40px;
    fill: #fff;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
}

.header-main-section .header-main-sec .navbar-menu li a.active-link .hover-svg-menu {
    fill: #FF1C1C !important;
}

.header-main-section .right-logo {
    margin-left: 30px;
}


.main-home-bg-section {
    background: url(../Images/bg.png);
    background-position: center;
    background-size: cover;
    height: 100%;
    padding: 260px 0 120px;
    position: relative;
}

/* .main-home-bg-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: hwb(280 4% 91% / 0.9);
} */

.main-home-bg-section .main-bg-section {
    position: relative;
}

.main-home-bg-section .main-bg-section .left-content-sec {
    /* width: 50%; */
}

.main-home-bg-section .main-bg-section .left-content-sec h1 {
    color: #F5FF00;
    font-size: 70px;
    font-family: 'Frozito';
}

p {
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
}

p a {
    color: #F2AA4C !important;
}

.top-main-button {
    margin-top: 30px;
    margin-bottom: 30px;
}

.top-main-button .yellow-bg-btn {
    margin-left: 15px;
}

.right-vector-sec {
    position: absolute;
    top: -25%;
    right: 0;
    z-index: 99;
}

.top-slider-bottom-big-img {
    position: relative;
    width: 100%;
    left: 0;
    top: 0px;
    margin-top: -7%;
    z-index: 0;
    overflow: hidden;
    height: 300px;

}


.top-slider-bottom-big-img .shap-img-top {
    width: 100%;
    height: auto;
    position: absolute;
    top: -5px;
    /* opacity: 0.1; */
}


.set-height {
    width: 100%;
    height: 100% !important;
}

.set-height video {
    width: 100%;
}

.set-height.main-big-video video {
    width: 100%;
    margin-top: 1px;
}

#set-garden.set-height video {
    width: 180%;
    position: absolute;
    top: 0;
    left: -35%;
}

#set-gun-men.set-height video {
    width: 180%;
    position: absolute;
    top: 0;
    left: -35%;
}

/* awards-sec */
.awards-sec {
    padding: 115px 0 0px;
    color: #fff;
    background: #130918;
    position: relative;
}

.awards-sec .center-title h2 {
    font-stretch: condensed;
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    position: relative;
    padding: 12px 0;
}

.awards-sec .center-title h2::before {
    content: "";
    position: absolute;
    background: url(../Images/title-border.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 75%;
    height: 4px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}


.awards-sec .center-title h2::after {
    content: "";
    position: absolute;
    background: url(../Images/title-border.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 75%;
    height: 4px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.awards-sec .left-award-img {
    position: absolute;
    top: 30px;
    left: 0;
}

.center-awards-text .desc-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0 30px;
}


.center-awards-text .desc-icons .coming-soon h6 {
    
    font-size: 25px;
    line-height: 30px;
    margin: 0;
    margin-right: 40px;
}

.center-desc-pera {
    text-align: center;
    width: 590px;
    margin: 0 auto;
}

/* animation-section  */
.animation-section {
    position: relative;
    padding: 50px 0 80px;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.animation-section.first-animation {
    background: url(../Images/bg2.png);
}

.animation-section::before {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.animation-section.dark-animation:before {
    background: url(../Images/bluroverlay.png);
}

.right-video-animation {
    position: relative;
    width: 80%;
    margin: 0 0 0 auto;
}

.animation-section .right-video-animation .text-animation-sec-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0;
}

.animation-section .right-video-animation .text-section {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 500px;
    text-align: center;
    color: #fff;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 99;
}

.animation-section .right-video-animation .text-section h1 {
    font-stretch: condensed;
    font-size: 48px;
    color: #C9C7C3;
    text-transform: uppercase;
    font-weight: 550;
    margin-bottom: 10px !important;     
}

.animation-section .right-video-animation .text-section button {
    margin-top: 27px;
}

.animation-section .right-video-animation .bg-ring {
    position: relative;
}

.animation-section .right-video-animation .bg-ring img {
    position: relative;

}

.animation-section .right-video-animation .bg-ring .video-animation-sec {
    position: absolute;
    top: 130px;
    right: 50px;
    height: 540px;
    width: 540px;
    background: #130918;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    overflow: hidden;
}


.animation-section.second-animation {
    background: url(../Images/bg3.png);
}

.animation-section.third-animation {
    background: url(../Images/bg4.png);
}

.animation-section.light-animation:before {
    background: url(../Images/bluroverlay-white.png);
}

/* animation Slider */
.animation-section .bg-ring .video-animation-sec .slider-sec {
    position: relative;
    margin: 50% 0;
    transform: translateY(-50%);
}

.animation-section .bg-ring .video-animation-sec .slider-sec .owl-carousel .owl-item img {
    margin: 0 auto;
    max-width: 400px;
}



/* Left Animation */


.left-video-animation {
    position: relative;
    width: 80%;
    margin: 0 auto 0 0;
    padding: 50px 0 10px;
}

.animation-section .left-video-animation .text-animation-sec-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 40px;
}

.animation-section .left-video-animation .text-section {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 540px;
    text-align: center;
    color: #130918;
    top: 57%;
    transform: translateY(-50%);
    right: 0;
    z-index: 99;
}

.animation-section .left-video-animation .text-section h1 {
    font-stretch: condensed;
    font-size: 48px;
    color: #130918;
    text-transform: uppercase;
    font-weight: 550;
    margin-bottom: 10px !important;
}

.animation-section .left-video-animation .text-section p {
    color: #130918;
    font-size: 19px;
    margin-left: auto;
}

.animation-section .left-video-animation .text-section button {
    margin-top: 27px;
    margin-left: auto;
    width: 202px;
}

.animation-section .left-video-animation .bg-ring {
    position: relative;
}

.animation-section .left-video-animation .bg-ring img {
    position: relative;

}

.animation-section .left-video-animation .bg-ring .video-animation-sec {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
    height: 560px;
    width: 560px;
    background: #3c5283cf;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    overflow: hidden;
}


/* blinking-animation */
.blinking-animation {
    position: relative;
    background: url(../Images/bg5.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 220px 0;
}

/* .blinking-animation::before {
    content: "";
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url(../Images/bluroverlay.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
} */

.blinking-animation .left-sec {
    width: 50%;
    z-index: 99;
    padding-left: 5%;
}

.blinking-animation .left-sec .text-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #fff;
    right: 210px;
    z-index: 99;
}

.blinking-animation .left-sec .text-section h1 {
    font-stretch: condensed;
    font-size: 48px;
    color: #C9C7C3;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px !important;
}

.blinking-animation .left-sec .text-section p {
    color: #fff;
    font-size: 20px;
    width: 470px;
}

.blinking-animation .left-sec .text-section img {
    margin-top: 10px !important;
}

.blinking-animation .right-sec {
    padding-right: 8%;
    width: 50%;
    display: flex;
    justify-content: flex-end;

}

.blinking-animation .custom-player-sec {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.blinking-animation .right-sec img {
    border-radius: 103px;
    z-index: 99;
    animation-name: my-animation;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    width: 500px;
    height: 500px;
}

@keyframes my-animation {
    from {
        box-shadow: 2px 2px 30px 10px #F5FF00;
    }

    to {
        box-shadow: 2px 2px 30px 20px #F5FF00;
    }
}

.title-sec.text-center {
    margin-bottom: 80px;
}

.title-sec h1 {
    font-stretch: condensed;
    font-size: 48px;
    color: #C9C7C3;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 10px;
}

/* box-slider-sec */

.box-slider-sec {
    padding: 80px 0 120px;
    position: relative;
    background: url(../Images/bg6.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.box-slider-sec::before {
    content: "";
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url(../Images/bluroverlay.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
}


/* team-section */
.team-section {
    padding: 100px 0 0px;
    position: relative;
    background: url(../Images/bg7.png);
    position: relative;
    background-position: center top;
    background-size: auto;
    background-repeat: no-repeat;
}

.team-section .title-sec {
    margin-bottom: 50px;
}

.team-section .team-member {
    width: 25%;
    padding-bottom: 35px;
}

.team-section .team-member .member-part {
    background: url(../Images/team/team-ring.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 7px;
    height: 200px;
    width: 200px;
    margin: 0 auto;
    position: relative;

}

.team-section .team-member .member-part img {
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    margin-top: 1px;
    height: 138px;
    width: 138px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.team-section .team-member .e-name {
    font-weight: 400;
    font-size: 19px;
    line-height: 29px;
    text-align: center;
    margin: 10px 0 0 0;
}

.team-section .team-member .e-position {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #F2AA4C;
}

.team-section .team-member .c-name {
    font-weight: 100;
    font-size: 15px;
    line-height: 29px;
    text-align: center;
    margin: 10px 0 0 0;
    padding-inline-start: 25px;
    padding-inline-end: 25px;
}

.all-team-member-sec {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 25px;
}



/* Owl Slider Start */
.slider-bottom-section {}

.slider-bottom-section .owl-item>div {
    cursor: pointer;
    margin: 6% 0;
    transition: margin 0.4s ease;
    -webkit-transition: margin 0.4s ease;
    -moz-transition: margin 0.4s ease;
    -ms-transition: margin 0.4s ease;
    -o-transition: margin 0.4s ease;
    height: 380px;
}

.slider-bottom-section .owl-carousel.owl-drag .owl-item {
    z-index: -1;
}

.slider-bottom-section .owl-item.active.center {
    z-index: 1;
}

.slider-bottom-section .owl-item.center>div {
    cursor: auto;
    margin: 0;
    position: absolute;
    width: 650px;
    z-index: 1111;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

/* .slider-bottom-section .owl-item:not(.center)>div:hover {
    opacity: .75;
} */
.slider-bottom-section .owl-carousel .owl-item img {
    object-fit: cover;
    height: 500px;
    border: 2px solid #140918;
}

.slider-bottom-section .owl-carousel .owl-item.active img {
    object-fit: cover;
    height: 500px;
    border: 2px solid #dbff00;
}


/* Customization */
.slider-bottom-section {
    width: 90%;
    margin: 0 auto;
}

.slider-bottom-section .owl-carousel .owl-stage {
    padding-top: 120px;
}

.slider-bottom-section .owl-carousel .owl-item.active {
    z-index: 1;
}

.slider-bottom-section .owl-carousel .owl-item.active img {
    height: 290px;
    width: 200px;
    margin-top: 24%;
    margin-right: auto;
    margin-left: unset;
}

.slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active {
    z-index: 11;
}

.slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active img {
    height: 360px;
    width: 270px;
    margin-top: 13%;
    margin-right: auto;
    margin-left: -35%;
}

.slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active {
    z-index: 111;
    cursor: not-allowed;
}

.slider-bottom-section .owl-item.active+.owl-item.active+.owl-item.active img {
    height: 446px;
    width: auto;
    margin-top: 0;
    margin: 0 auto;
}

.slider-bottom-section .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active {
    z-index: 11;
}

.slider-bottom-section .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active img {
    height: 360px;
    width: 270px;
    margin-top: 13%;
    margin-right: -35%;
    margin-left: auto;
}

.slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active {
    z-index: 0;
}

.slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active img {
    height: 290px;
    width: 200px;
    margin-top: 24%;
    margin-right: unset;
    margin-left: auto;
}

/* Owl Slider Start */
/* network-section  */
.network-section {
    padding: 50px 0 35px;
}
.network-section .row{
    justify-content: center;
}
.network-section .title-sec {
    margin-bottom: 60px;
}

.network-section .bottom-logo-img {
    margin-bottom: 70px;
    text-align: center;
}

.network-section .bottom-logo-img img {
    margin: 0 auto;
}



/* footer-section */


.footer-section {
    position: relative;
    background-color: #F2AA4C;
    padding: 110px 0 0;
}

.footer-section::before {
    content: "";
    left: 0;
    width: 100%;
    height: 100px;
    position: absolute;
    background: url(../Images/content-bottom-shap.png);
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    top: -5px;
    left: 0;
}


.footer-section .center-bottom-link ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    padding-left: 0;
}

.footer-section .center-bottom-link ul li a {
    color: #000000;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    padding: 0 0px;
}

.footer-bottom {
    margin-top: 40px;
    background: #130918;
    padding: 25px 10% 10px 10% !important;
    position: relative;

}

.footer-bottom::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: 0;
    height: 0;
    border-top: 75px solid #F2AA4C;
    border-right: 75px solid transparent;
}

.footer-bottom .social-icons {
    width: 50%;
}

.footer-link-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-bottom .social-icons ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
}

.footer-bottom .social-icons ul li {
    margin: 0 25px 0 0;
    display: flex;
    align-items: center;
}

.footer-bottom .social-icons ul li a svg {}

.footer-bottom .copyright {
    text-align: center;
    margin-top: 30px;
}

.footer-bottom .copyright p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #DBFF00;
    border-radius: 10px;
    height: 150px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
