@media only screen and (max-width: 1367px) {
    .btn {
        font-size: 21px;
        padding: 13px;
        font-weight: bold;
    }

    p {
        font-size: 21px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    .center-desc-pera p {
        font-size: 21px;
        line-height: 26px;
        margin-bottom: 40px;
    }

    .hover-svg-menu {
        width: 39px;
        height: 39px;
    }
    .box-slider-sec .options .option .list-question{
        margin-top: 10px;
    }
    .header-main-section .header-main-sec .navbar-brand img {
        width: 125px;
    }
    .box-slider-sec .options .option.slide1 img{
        width: 145px;
        
    }
    .box-slider-sec .options .option.slide2 img{
        width: 155px;
    }
    .box-slider-sec .options .option.slide3 img{
        width: 155px;
    margin-left: 10px;
    }
    .box-slider-sec .options .option.slide4 img{
        width: 155px;
        /* height: auto; */
    }
    .main-home-bg-section .main-bg-section .left-content-sec h1{
        line-height: 70px;
        margin-bottom: 5px;
    }
    .main-home-bg-section .main-bg-section .left-content-sec p{
        margin-bottom: 0;
    }
    .top-main-button {
        margin-top: 20px;
    }
    .right-vector-sec {
        right: -10px;
    }
    .blinking-animation .right-sec{
        padding-right: 11%;
    }

    /* .awards-sec .center-title h2{
        font-size: 35px;
    } */
    .center-awards-text .desc-icons .coming-soon h6 {
        font-size: 25px;
        margin-right: 30px;
    }
    .center-awards-text .desc-icons img{
        width: 170px;
    }
    .awards-sec .center-awards-text .center-desc-pera{
        width: 525px;
    }
    .awards-sec .center-awards-text .center-desc-pera p{
        font-size: 20px;
        letter-spacing: 0.5px;
    }
    .center-desc-pera p {
        letter-spacing: 0.6px;
    }

    .awards-sec .center-title {
        margin-left: 70px;
    }

    .awards-sec .left-award-img {
        margin-left: 50px;
    }

    .awards-sec .center-awards-text {
        margin-left: 70px;
    }

    .animation-section .right-video-animation .bg-ring .video-animation-sec {
        height: 520px;
        width: 520px;
    }

    .animation-section .right-video-animation .text-section {
        left: 0px;
        top: 53%;
    }

    .animation-section .right-video-animation .text-section h1 {
        letter-spacing: 1px;
        margin-bottom: 10px;
        font-size: 45px;
    }
    .right-video-animation{
        width: 81.5%;
    }

    .animation-section .left-video-animation .bg-ring .video-animation-sec {
        height: 550px;
        width: 550px;
    }
    .left-video-animation{
        width: 79%;
    }
    .animation-section .right-video-animation .text-section button {
        margin-top: 10px;
        font-size: 20px;
        width: 202px;
    }

    .animation-section .left-video-animation .text-section {
        right: -130px;
        top: 56%;
    }

    .blinking-animation .right-sec img {
        width: 450px;
        height: 470px;
    }

    .animation-section .left-video-animation .text-section h1 {
        margin-bottom: 10px !important;
        letter-spacing: 1px;
        font-size: 45px;
        margin-left: auto;
    }

    .animation-section .left-video-animation .text-section button {
        margin-top: 10px;
        width: 202px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .animation-section .left-video-animation .text-section p {
        font-weight: bold;
    }

    .animation-section.third-animation {
        padding: 70px 0;
    }

    .network-section {
        padding: 100px 0 0px;
    }

    .blinking-animation .left-sec {
        padding-left: 0;
        margin-left: 50px;
    }

    .blinking-animation {
        background-position: left;
    }

    .blinking-animation .left-sec .text-section h1 {
        letter-spacing: 1px;
        font-size: 40px;
        margin-bottom: 15px;
    }
    .blinking-animation .left-sec .text-section p{
        font-size: 21px;
        line-height: 26px;
    }
    .box-slider-sec .options .option.active {
        padding: 15px 50px 15px 70px;
    }

    .main-home-bg-section {
        padding: 250px 0 92px;
    }

    .right-vector-sec {
        top: -105px;
    }
    .right-vector-sec img{
        width: 103%;
    }
    .awards-sec .center-title h2{
        font-size: 40px;
    }
    .title-sec h1{
        font-size: 45px;
    }
    .footer-section .center-bottom-link{
        padding: 0 30px;
    }
    .footer-bottom {
        padding: 25px 6% 10px 15%;
    }

    .footer-section::before {
        top: -6%;
    }
    .footer-bottom .social-icons {
        width: 47%;
    }

    .footer-bottom .social-icons ul li {
        margin: 0 20px 0 0;
    }

    .footer-link-logo .right-logo img {
        width: 220px;
    }
    .footer-bottom .social-icons ul li:last-child{
        margin: 0;
    }

    /* Custom Slider */
    .slider-bottom-section .owl-carousel .owl-item.active img {
        height: 370px;

    }

    .slider-bottom-section .owl-carousel .owl-item.active img {
        height: 290px;
        width: 180px;
        margin-top: 33%;
        margin-right: auto;
        margin-left: unset;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active {
        z-index: 11;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active img {
        height: 360px;
        width: 270px;
        margin-top: 18%;
        margin-right: auto;
        margin-left: -45%;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active {
        z-index: 111;
        cursor: not-allowed;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active img {
        height: 446px;
        width: auto;
        margin-top: 0;
        margin: 0 auto;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active {
        z-index: 11;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active img {
        height: 360px;
        width: 270px;
        margin-top: 18%;
        margin-right: -45%;
        margin-left: auto;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active {
        z-index: 0;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active img {
        height: 290px;
        width: 180px;
        margin-top: 33%;
        margin-right: unset;
        margin-left: auto;
    }

    .list-question ul{
        height: 343px !important;
        max-height: 345px;
        overflow: hidden;
    }

}

@media only screen and (min-width: 1024px) {
    .desktop-sec {
        display: block !important;
    }

    .mobile-sec {
        display: none !important;
    }


}

@media only screen and (max-width: 1024px) {
    .main-home-bg-section {
        padding: 350px 0 150px;
    }

    .right-vector-sec {
        right: -60px;
    }

    .team-section .team-member {
        width: 50%;
        margin-top: 30px;
    }

    .top-slider-bottom-big-img {
        margin-top: -85px;
    }

    .top-main-button .yellow-bg-btn {
        margin: 20px 0 0 0;
    }

    .awards-sec {
        padding: 70px 0 0;
    }

    .awards-sec .left-award-img {
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
    }

    .awards-sec .center-awards-text {
        margin-top: 250px;
    }

    .right-video-animation {
        width: 100%;
    }

    .animation-section {
        padding: 0;
    }

    .animation-section .right-video-animation .text-section {
        position: unset;
    }

    .animation-section .right-video-animation .text-section {
        position: unset;
        margin: 0 auto;
    }

    .animation-section .right-video-animation .text-animation-sec-right {
        justify-content: center;
        margin-right: 0;
    }

    .desktop-sec {
        display: none !important;
    }

    .mobile-sec {
        display: block !important;
    }

    .animation-section .right-video-animation .text-section.mobile-sec {
        width: 100%;
        margin-top: 100px;
    }

    .left-video-animation {
        width: 100%;
    }

    .animation-section .left-video-animation .text-animation-sec-left {
        justify-content: center;
        margin-left: 0;
    }

    .animation-section .left-video-animation .text-section {
        position: unset;
        margin: 0 auto;
    }

    .animation-section .left-video-animation .text-section.mobile-sec {
        width: 100%;
        margin-top: 100px;
    }

    .left-video-animation {
        padding: 1px 0;
    }

    .animation-section.third-animation {
        padding: 0 0;
    }

    .blinking-animation {
        padding: 0 0 50px;
    }

    .blinking-animation .custom-player-sec {
        flex-direction: column;
    }

    .blinking-animation .right-sec {
        padding: 0;
        margin-top: 50px;
        z-index: 99;
    }

    .blinking-animation .left-sec {
        width: 100%;
        margin-left: 0;
    }

    .blinking-animation .left-sec p {
        margin-top: 80px;
    }

    .animation-section .right-video-animation .text-section.mobile-sec p {
        width: 60%;
        margin: 0 auto;
    }

    .animation-section .left-video-animation .text-section.mobile-sec p {
        width: 60%;
        margin: 0 auto;
    }

    .box-slider-sec {
        padding-bottom: 0;
    }

    .network-section {
        padding: 50px 0 25px;
    }

    .footer-section::before {
        top: -25px;
    }

    .footer-section {
        padding: 100px 0 0;
    }

    .footer-bottom::before {
        top: -5px;
    }

    .footer-link-logo {
        flex-direction: column;
    }

    .footer-bottom .social-icons {
        width: 100%;
    }


    .slick-slide img {
        border: none !important;
    }


    /* Custom Slider */
    .slider-bottom-section {
        width: 95%;
    }

    .slider-bottom-section .owl-carousel .owl-item.active img {
        height: 370px;

    }

    .slider-bottom-section .owl-carousel .owl-item.active img {
        height: 290px;
        width: 180px;
        margin-top: 33%;
        margin-right: auto;
        margin-left: unset;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active img {
        height: 360px;
        width: 270px;
        margin-top: 18%;
        margin-right: auto;
        margin-left: -65%;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active img {
        height: 446px;
        width: auto;
        margin-top: 0;
        margin: 0 auto;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active img {
        height: 360px;
        width: 270px;
        margin-top: 18%;
        margin-right: -65%;
        margin-left: auto;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active img {
        height: 290px;
        width: 180px;
        margin-top: 33%;
        margin-right: unset;
        margin-left: auto;
    }
}

@media only screen and (max-width: 991px) {
    .right-vector-sec {
        right: 0;
        top: 75%;
    }

    .right-vector-sec img {
        width: 100%;
    }

    .main-home-bg-section {
        padding: 200px 0 150px;
    }

    .set-height.main-big-video video {
        margin-top: 0%;
    }

    .footer-section::before {
        top: -45px;
    }

    /* Custom-Slider */
    .slider-bottom-section .owl-item>div {
        height: 275px;
    }

    .slider-bottom-section .owl-item.center>div {
        width: 480px;
    }

    .slider-bottom-section .owl-carousel .owl-item.active img {
        height: 210px;
        width: 135px;
        margin-top: 40%;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active img {
        height: 270px;
        width: 150px;
        margin-top: 21%;
        margin-left: -65%;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active img {
        height: 335px;
        width: auto;
        margin-top: 0;
        margin: 0 auto;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active img {
        height: 270px;
        width: 150px;
        margin-top: 21%;
        margin-right: -65%;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active img {
        height: 210px;
        width: 135px;
        margin-top: 40%;
    }

    .blinking-animation .left-sec .text-section h1 {
        margin-top: 50px;
    }

    .blinking-animation .right-sec img {
        height: auto;
        width: 100%;
    }

}

@media only screen and (max-width: 767px) {
    .header-main-section .header-main-sec .navbar-brand img {
        width: 50px;
    }
    .header-main-section .header-main-sec {
        justify-content: center;
    }

    .team-section .team-member {
        width: 100%;
    }

    .slick-slide img {
        height: 150px;
    }

    .slick-prev {
        top: 125px !important;
        left: 0px !important;
    }

    .slick-next {
        top: 125px !important;
        right: 0px !important;
    }

    .navbar-brand img {
        width: 50px;
    }

    .navbar-nav {
        flex-direction: row;
    }

    .navbar-nav .nav-link svg {
        width: 20px;
    }

    .header-main-section .right-logo {
        margin-left: 0;
    }

    .header-main-section .right-logo a img {
        width: 110px;
    }

    .navbar-toggler-icon {
        display: none;
    }

    .main-home-bg-section .main-bg-section .left-content-sec h1 {
        font-size: 40px;
        text-align: center;
    }

    .right-vector-sec {
        right: 0;
        margin-top: 40px;
    }

    .main-home-bg-section {
        padding: 120px 0 130px;
    }

    .right-vector-sec img {
        width: 225px;
    }

    p {
        font-weight: 600;
        font-size: 18px;
    }

    .btn {
        padding: 13px 15px;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        width: 80%;
    }

    .top-main-button {
        text-align: center;
    }

    .set-height.main-big-video video {
        width: 100%;
        margin-top: -0%;
    }

    .top-slider-bottom-big-img .shap-img-top {
        top: -3px;
    }

    .top-slider-bottom-big-img {
        margin-top: 11px;
        height: 270px;
    }

    .awards-sec {
        padding: 0px 0 0;
    }

    .animation-section .bg-ring .video-animation-sec .slider-sec {
        margin: 0 auto;
        transform: unset;
        -webkit-transform: unset;
        -moz-transform: unset;
        -ms-transform: unset;
        -o-transform: unset;
    }

    .blinking-animation .left-sec .text-section h1,
    .animation-section .left-video-animation .text-section h1,
    .animation-section .right-video-animation .text-section h1,
    .awards-sec .center-title h2 {
        font-size: 26px;
        line-height: 30px;
        padding: 12px 0;
    }
    .blinking-animation .left-sec{
        margin-left: 0;
    }
    .team-section .title-sec h1{
        text-align: center;
    }
    .blinking-animation .right-sec {
        width: 75%;
    }
    .footer-bottom .social-icons ul li {
        margin: 0 0px 0 0;
    }
    .hover-svg-menu {
        width: 29px;
        height: 21px;
    }

    .blinking-animation .right-sec img {
        border-radius: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
    }
    .awards-sec .center-awards-text{
        margin-left: 0;
    }
    .center-awards-text .desc-icons .coming-soon h6{
        margin: 0;
    }
    .awards-sec .center-awards-text .center-desc-pera {
        width: 100%;
    }
    .awards-sec .center-title{
        margin-left: 0;
    }
    .awards-sec .left-award-img {
        top: 90px;
        margin-left: 0;
    }

    .center-awards-text .desc-icons {
        flex-direction: column;
    }

    .center-awards-text .desc-icons img {
        margin-top: 20px;
    }

    .center-desc-pera {
        width: auto;
    }

    .animation-section .right-video-animation .bg-ring {
        overflow: hidden;
    }

    .animation-section .right-video-animation .bg-ring .video-animation-sec {
        top: 46px;
        right: 15px;
        height: 75%;
        width: 75%;
    }

    .animation-section .left-video-animation .bg-ring .video-animation-sec {
        top: 50%;
        left: 15px;
        height: 75%;
        width: 75%;
    }

    .animation-section .bg-ring .video-animation-sec .slider-sec .carousel .carousel-item img {
        max-width: 75%;
    }

    .blinking-animation .left-sec .text-section p,
    .animation-section .right-video-animation .text-section.mobile-sec p,
    .animation-section .left-video-animation .text-section.mobile-sec p {
        width: 90%;
    }

    .blinking-animation .left-sec .text-section p,
    .animation-section .left-video-animation .text-section p {
        font-size: 18px;
        font-weight: 600;
    }

    .box-slider-sec {
        padding-top: 30px;
    }

    .options .option:nth-child(1),
    .options .option:nth-child(2),
    .options .option:nth-child(3),
    .options .option:nth-child(4) {
        display: block;
    }

    .box-slider-sec .slider-sec .options h1 {
        font-size: 20px;
    }

    .box-slider-sec .options .option.active {
        padding: 15px;
    }

    .box-slider-sec .options {
        min-height: auto;
        max-height: inherit;
    }

    .team-section {
        padding-top: 50px;
    }

    .footer-section {
        padding: 50px 0 0;
    }

    .footer-section::before {
        top: -75px;
    }

    .network-section .bottom-logo-img {
        margin-top: 25px;
    }

    .footer-section .center-bottom-link ul {
        margin-top: 25px;
        padding: 0;
        flex-direction: column;
    }

    .footer-bottom {
        padding: 50px 10px 20px;
    }

    .footer-bottom::before {
        border-top: 50px solid #F2AA4C;
        border-right: 50px solid transparent;
        top: -3px;
        left: -3px;
    }

    .animation-section .right-video-animation .text-section.mobile-sec {
        margin-top: 55px;
    }

    .text-section.mobile-sec p {
        margin-top: 100px !important;
    }

    .blinking-animation .right-sec img {
        height: auto;
        width: 100%;
    }

    .box-slider-sec .options .option img {
        height: auto;
    }

    .title-sec h1 {
        font-size: 36px;
    }

    .team-section .title-sec {
        margin-bottom: 15px;
    }

    /* Custom Slider */
    .slider-bottom-section .owl-carousel .owl-stage {
        padding-top: 50px;
    }

    /* Custom-Slider */
    .slider-bottom-section .owl-item>div {
        height: 155px;
    }

    .slider-bottom-section .owl-carousel .owl-item img {
        height: 182px;
    }

    .slider-bottom-section .owl-item.center>div {
        width: 205px;
    }

    .slider-bottom-section .owl-carousel .owl-item.active img {
        height: 70px;
        width: 65px;
        margin-top: 55%;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active img {
        height: 100px;
        width: 60px;
        margin-top: 33%;
        margin-left: -54%;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active img {
        height: 145px;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active img {
        height: 100px;
        width: 60px;
        margin-top: 33%;
        margin-right: -54%;
    }

    .slider-bottom-section .owl-carousel .owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active+.owl-item.active img {
        height: 70px;
        width: 65px;
        margin-top: 55%;
    }

    /*  */
    .network-section {
        padding: 00px 0 25px;
    }

  
}

/* @media only screen and (max-width:1368px) {
    .list-question ul{
        line-height: 32px;
    }
} */

@media only screen and (max-width: 575px) {
.animation-section .bg-ring .video-animation-sec .slider-sec .owl-carousel .owl-item img {
    width: 200px;
    margin-top: 38px;
}
.box-slider-sec .options .option.active ul{
    max-height: fit-content;
    height: calc(100% - 40px) !important;

}
}

@media only screen and (max-width: 515px) {
    .animation-section .bg-ring .video-animation-sec .slider-sec .owl-carousel .owl-item img {
        width: 200px;
        margin-top: 80px;
    }
   
    }
    @media only screen and (max-width: 400px) {
        .animation-section .bg-ring .video-animation-sec .slider-sec .owl-carousel .owl-item img {
            width: 200px;
            margin-top: 38px;
        }
       
        }
