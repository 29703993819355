

.box-slider-sec .credit {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: inherit;
}

.box-slider-sec .options {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  min-width: 100%;
  max-width: 100%;
  width: calc(100% - 100px);
  min-height: 550px;
  max-height: 550px;
  overflow: hidden;
  background: #2a1b31cc;
}


.options .option {
  position: relative;
  overflow: hidden;
  max-width: 25%;
  background-size: auto 120%;
  background-position: center;
  cursor: pointer;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  border: 2px solid #f2aa4c;
  
}
.box-slider-sec .slider-sec .options h1 {
  font-size: 40px;
  letter-spacing: normal;
  text-transform: uppercase;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-family: 'Lato', sans-serif;
  margin: 0 auto;
}

.box-slider-sec .options .option .top-main-section{
  text-align: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
}
.box-slider-sec .options .option.active .top-main-section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.box-slider-sec .options .option h1{
  writing-mode: vertical-lr;
  text-orientation: upright;
}

.box-slider-sec .options .option.active {
  flex-grow: 10000;
  transform: scale(1);
  max-width: 75%;
  margin: 0px;
  background-size: auto 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  padding: 15px 50px;
}


.box-slider-sec .options .option.active h1{
  writing-mode: horizontal-tb !important;
  text-orientation: upright !important;
  -webkit-writing-mode: horizontal-tb !important;
  -ms-writing-mode: horizontal-tb !important;
  -webkit-text-orientation: upright !important;
  margin: 0;
  margin-top: 15px;
}
.box-slider-sec .options .option.active img{
  margin-top: 0;
  margin-left: 25px;
}

.box-slider-sec .options .option:not(.active) {
  flex-grow: 1;
  padding: 35px 0;
  width: 8.3333%;
}

.box-slider-sec .options .option:not(.active) .list-question,
.box-slider-sec .options .option:not(.active) img{
  display: none !important;
}
.options .option .options .option.slider-close img{
  display: block !important;
}
.box-slider-sec .options .option:not(.active) .slide-sec-part{
  height: 100%;
}
.box-slider-sec .options .option:not(.active) .top-main-section{
  padding: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.box-slider-sec .options .option:not(.active) .top-main-section h1{
  writing-mode: vertical-lr !important;
  text-orientation: upright !important;
  -webkit-writing-mode: vertical-lr !important;
  -ms-writing-mode: vertical-lr !important;
  -webkit-text-orientation: upright !important;
}

.box-slider-sec .options .option .list-question{
  min-height: auto;
  position: relative;
  margin-top: -20px;
    margin-left: 30px;
}
.box-slider-sec .options .option.active .list-question:before{
  content: "";
   position: absolute;
   background: red;
   height: calc(100% - 32px);
   width: 1px;
   left: 0;
   top: 32px;
}
.box-slider-sec .options .option .top-main-section h1{
  writing-mode: horizontal-tb !important;
  text-orientation: upright !important;
  -webkit-writing-mode: horizontal-tb !important;
  -ms-writing-mode: horizontal-tb !important;
  -webkit-text-orientation: upright !important;
}
.box-slider-sec .options .option.active .top-main-section h1{
  writing-mode: horizontal-tb;
  text-orientation: upright;
  -webkit-writing-mode: horizontal-tb;
  -ms-writing-mode: horizontal-tb;
}


.box-slider-sec .options.box-slider-default .option{
  align-items: center;
    display: flex;
    justify-content: center;
}
.box-slider-sec .options.box-slider-default .option .slide-sec-part{
  width: 100%;
  height: 100%;
}

.box-slider-sec .options.box-slider-default .option .top-main-section h1{
  writing-mode: horizontal-tb !important;
  text-orientation: upright !important;
  -webkit-writing-mode: horizontal-tb !important;
  -ms-writing-mode: horizontal-tb !important;
  -webkit-text-orientation: upright !important;
}
.box-slider-sec .options.box-slider-default .option img{
  display: block !important;
}
.box-slider-sec .options .option img{
  margin-top: 30px;
  height: 135px;
  display: block;
}

.box-slider-sec .options .option.active .top-main-section{
  padding: 0;
}
.box-slider-sec .options .option.active h6{
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.box-slider-sec .options .option.active ul{
  padding-left: 35px;
    height: calc(100% - 40px);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: bold;
}
.box-slider-sec .options .option.active ul li{
  color: #fff;
  letter-spacing: 1px;
  
}
@media screen and (max-width: 718px) {
  .box-slider-sec.options {
    min-width: 520px;
  }  
}

@media screen and (max-width: 638px) {
  .options {
    min-width: 440px;
  }

  .options .option:nth-child(4) {
    display: none;
  }
}

@media screen and (max-width: 558px) {
  .options {
    min-width: 360px;
  }

  .options .option:nth-child(3) {
    display: none;
  }
}

@media screen and (max-width: 478px) {
  .options {
    min-width: 280px;
  }

  .options .option:nth-child(2) {
    display: none;
  }
}
